<template>
  <div class="side-bar">
    <ui-button
        @click="changeVisibleSideBar"
        class="side-bar__activator"
        icon
        color="error">
      <img src="../../assets/svg/sideBar/menu.svg" alt="" />
    </ui-button>
<!--    <img-->
<!--        @click="changeVisibleSideBar"-->
<!--        class="side-bar__icon-mob"-->
<!--        src="../../assets/svg/menu-red.svg"-->
<!--        alt=""-->
<!--    >-->
    <transition name="slide-right">
      <div v-if="isVisibleSideBar" @click.self="changeVisibleSideBar" class="side-bar__backdrop">
        <div class="side-bar__inner">
          <div class="side-bar__header">
            <img @click="changeVisibleSideBar" class="side-bar__header-img" src="../../assets/svg/arrows/back.svg" alt="">
            <router-link to="/login">
              <ui-button @click="changeVisibleSideBar" color="error">
                {{ $t('log-in') }}
              </ui-button>
            </router-link>
          </div>
          <nav class="side-bar__nav">
            <router-link
                v-for="(item, key) in data"
                :key="key"
                :to="item.path"
                class="side-bar__nav-link"
                @click.native="changeVisibleSideBar"
            >
              <img :src="item.icon" alt="" />
              {{ $t(item.text) }}
            </router-link>
          </nav>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "SideBar",
  components: {
    UiButton: () => import('@/components/ui/UiButton'),
  },

  data() {
    return {
      data: [
        { id: 1, text: 'map', icon: require('@/assets/svg/sideBar/icon-side-bar-1.svg'), path: '/' },
        { id: 2, text: 'rating-of-activists', icon: require('@/assets/svg/sideBar/icon-side-bar-2.svg'), path: '/activists-rating'  },
        { id: 3, text: 'rating-of-regions', icon: require('@/assets/svg/sideBar/icon-side-bar-3.svg'), path: '/data-region'  },
        // { id: 4, text: 'Программа лояльности',icon: require('@/assets/svg/sideBar/icon-side-bar-4.svg'), path: ''  },
        { id: 5, text: 'events', icon: require('@/assets/svg/sideBar/icon-side-bar-5.svg'), path: '/event'  },
        { id: 6, text: 'partners', icon: require('@/assets/svg/sideBar/icon-side-bar-6.svg'), path: '/partners'  },
        { id: 7, text: 'technical-support-1', icon: require('@/assets/svg/sideBar/icon-side-bar-7.svg'), path: 'tech-support'  },
        { id: 8, text: 'contacts', icon: require('@/assets/svg/sideBar/icon-side-bar-8.svg'), path: 'contacts' },
        { id: 9, text: 'about-app', icon: require('@/assets/svg/sideBar/icon-side-bar-9.svg'), path: 'about'  },
      ]
    }
  },

  methods: {
    ...mapMutations([
       'changeVisibleSideBar'
    ]),
  },

  computed: {
    ...mapGetters([
      'isVisibleSideBar'
    ])
  }
}
</script>

<style lang="scss" scoped>
.side-bar {

  //&__activator {
  //  position: absolute;
  //  top: 50px;
  //  left: 50px;
  //
  //  @media (max-width: 768px) {
  //    top: 55px;
  //    left: 20px;
  //  }
  //}
  //&__activator {
  //  @media (max-width: 768px) {
  //    display: none !important;
  //  }
  //}
  //&__icon-mob {
  //  display: none;
  //  cursor: pointer;
  //  width: 32px;
  //  height: 32px;
  //  @media (max-width: 768px) {
  //    display: block;
  //  }
  //}

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.3s;
    z-index: 100;
    background: rgba(153, 153, 153, 0.8);
    backdrop-filter: blur(2.5px);
    padding-right: 71px;
  }

  &__inner {
    position: relative;
    max-width: 406px;
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 60px 20px 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);

    &-img{
      cursor: pointer;
      margin-bottom: 53px;
    }
  }

  &__nav {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 50px;
    overflow-y: auto;

    @media (max-width: 768px) {
      padding: 20px 30px;
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #343432;
      text-decoration: none;
    }
  }
}
</style>
