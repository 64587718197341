<template>
  <header class="header">
    <div class="container">
      <div class="header__sort" v-if="$route.path == '/'">
        <div :class="['header__sort-chip', 'all', {checked: getMapSortBy?.id === all.id}]" @click="setMapSort(all)">
          <p class="header__sort-chip-text">
            {{ all.text }}
          </p>
        </div>
        <div class="header__sort-slider slider">
          <img @click="prev" class="slider__prev" src="@/assets/svg/arrows/up-black.svg" alt="" v-if="sliderTranslate !== 0">
          <div class="slider__wrapper">
            <div :style="translate" class="slider__wrapper-items">
              <div
                  :class="['header__sort-chip', {checked: getMapSortBy?.id === item.id}]"
                  v-for="(item, key) in getDamageRanges"
                  :key="key"
                  @click="setMapSort(item)"
                  v-show="item.status !== 'Moderation' || getUser?.role === 'Admin' || getUser?.role === 'Moderator'"
              >
                <div class="header__sort-chip-circle" :style="{backgroundColor: item.color}" v-if="item.color"></div>
                <div class="header__sort-chip-text">
                  <p v-html="item.text"></p>
                  <span v-if="item.minValue === 1000001">{{ $t('filter-subtitle-1') }}</span>
                  <span v-if="item.minValue === 500001">{{ $t('filter-subtitle-2', {from: '500 001', to: '1 000 000'}) }}</span>
                  <span v-if="item.minValue === 100001">{{ $t('filter-subtitle-2', {from: '100 001', to: '500 000'}) }}</span>
                  <span v-if="item.minValue === 0">{{ $t('filter-subtitle-3', {to: '100 000'}) }}</span>
                </div>
              </div>
            </div>
          </div>
          <img @click="next" class="slider__next" src="@/assets/svg/arrows/up-black.svg" alt="" v-show="sliderTranslate === 0">
        </div>
      </div>
      <div class="header__content">
        <side-bar />
      </div>
    </div>
  </header>
</template>

<script>
import sideBar from "@/components/common/SideBar";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "Header",
  components: {
    sideBar
  },

  data () {
    return {
      all: {id: 0, text: this.$t('all'), status: 'All'},
      index: 0,
      sliderTranslate: 0
    }
  },

  computed: {
    ...mapGetters([
      'getMapSortBy',
      'getUser',
      'getDamageRanges'
    ]),

    translate() {
      return {transform: `translateX(-${this.sliderTranslate}px)`}
    },
  },

  methods: {
    ...mapMutations([
      'setMapSort',
    ]),
    ...mapActions([
      'orderDamageRanges'
    ]),

    prev() {
      this.sliderTranslate = 0
    },
    next() {
      if(window.innerWidth < 1200) {
        this.sliderTranslate = 310
      } else if(window.innerWidth < 1300) {
        this.sliderTranslate = 240
      } else if(window.innerWidth < 1400) {
        this.sliderTranslate = 160
      }
    }
  },

  created() {
    if (this.getDamageRanges.length === 3) {
      this.orderDamageRanges()
    }
    this.setMapSort(this.all)
  },

  destroyed() {
    this.setMapSort(this.all)
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 50;
  background: transparent;

  &__sort {
    position: absolute;
    top: 46px;
    left: 104px;
    right: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 50;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 768px) {
      max-width: 600px;
      right: 30px;
      left: 80px;
      top: 28px;
    }

    &-chip {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      padding: 10px 16px;
      border-radius: 100px;
      background: #FFFFFF;
      box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
      cursor: pointer;
      height: 48px;

      &.all {
        align-items: center;
      }

      &.checked {
        background: #E21F1F;

        .header__sort-chip-text {
          color: #FFFFFF;

          span {
            color: #FFFFFF;
          }
        }
      }

      &-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-top: 3px;
      }

      &-text {
        color: #1B1A1F;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        gap: 3px;

        span {
          color: #1B1A1F;
          font-family: "Gotham Pro Regular";
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .slider {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      &__prev {
        transform: rotate(-90deg);
        padding: 10px 10px;
        border-radius: 100px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
        z-index: 1;
        cursor: pointer;

        @media (max-width: 1100px) {
          display: none;
        }
      }

      &__next {
        transform: rotate(90deg);
        padding: 10px 10px;
        border-radius: 100px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
        z-index: 1;
        cursor: pointer;
        display: none;

        @media (max-width: 1400px) {
          display: inline-block;
        }

        @media (max-width: 1100px) {
          display: none;
        }
      }

      &__wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 50px;

        @media (max-width: 1400px) {
          display: inline-block;
        }

        @media (max-width: 1100px) {
          padding-right: 90px;
        }

        &-items {
          position: absolute;
          margin-right: v-bind(userBoxWidth);
          display: flex;
          gap: 10px;
          transition: .3s;
          padding-left: 10px;
          width: 100%;

          @media (max-width: 1100px) {
            position: static;
            overflow-y: scroll;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  &__content {
    padding: 50px;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      padding: 30px 20px;
    }
  }
}
</style>
